import React from 'react'


function Privacy() {
  return (
    <div style={{ backgroundColor: '#eee', justifyContent: 'center', display: 'flex' }}>

      <div style={{ width: '800px', }}>
        <iframe style={{ width: '100%', height: '100vh', border: 0 }} src="https://docs.google.com/document/d/e/2PACX-1vS8mAuGHzewr1AGwm7NBpuR1FrUAKX3LapAaMbdgIb9DCNNBXLUHS5Tt5C5AN8_qg/pub?embedded=true"></iframe>
      </div>
    </div>
  )
}

export default Privacy