import React from 'react'
import '../terms.css';



function Terms() {
  return (
    <div style={{ backgroundColor: '#eee', justifyContent: 'center', display: 'flex' }}>

      <div style={{ width: '800px', }}>
        <iframe style={{ width: '100%', height: '100vh', border: 0 }} src="https://docs.google.com/document/d/e/2PACX-1vTE74kzG2iN7cauGijOdrpGAViMj4hUgmGylux4pHMGjt9iS8jaxUYoYeS7nAHmTg/pub?embedded=true"></iframe>
      </div>
    </div>
  )
}

export default Terms