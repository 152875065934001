import logo from './logo.svg';
import './App.css';
import './hover.css';

import React, { Suspense, useEffect, useState } from 'react';
import Home from './Screens/Home';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Privacy from './Screens/Privacy';
import Terms from './Screens/Terms';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,

  },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "terms",
    element: <Terms />,
  },

]);


function App() {

  return (
    <div>
      <RouterProvider router={router} />
    </div>
    // <div className="App">
    //   <Home/>

    // </div>
  );
}

export default App;


